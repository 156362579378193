import { SwipeableDrawer } from '@material-ui/core';
import useStyles from './styles';
import { IDetailedDrawerProps } from './types';
import useDetailedDrawer from './useDetailedDrawer';

export const DetailedDrawer = ({
  children,
  isDrawerOpen,
  showOnlyOnDesktop = false,
  onDrawerStateChange,
}: IDetailedDrawerProps) => {
  const classes = useStyles();
  const { showDrawerHandler } = useDetailedDrawer({
    onDrawerStateChange,
    showOnlyOnDesktop,
    isDrawerOpen,
  });
  return (
    <SwipeableDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={showDrawerHandler(false)}
      onOpen={showDrawerHandler(true)}
      PaperProps={{ className: classes.drawerPaper }}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default DetailedDrawer;
