import { FC } from 'react';
import useRoleGates from './useRoleGates';
import { IRoleGateProps } from './types';

export const RoleGates: FC<IRoleGateProps> = ({ children, compareRoles }) => {
  const { hasAccess } = useRoleGates(compareRoles);

  if (!hasAccess) {
    return null;
  }

  return <>{children}</>;
};

export default RoleGates;
