import { Button } from '@/client/components';
import { Typography, ButtonBase } from '@material-ui/core';
import Image from 'next/image';
import useStyles from './styles';
import { IDrawerHeaderProps } from './types';

const DrawerHeader = ({ backButton, openNewTabHref }: IDrawerHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Button variant="text" onClick={backButton} className={classes.backBtn}>
        <Image src="/icons/chevron-left.svg" width={11} height={19} />
      </Button>
      <div className={classes.openInTab}>
        <ButtonBase href={openNewTabHref} target="_blank" rel="noreferrer noopener" disableRipple>
          <Typography className={classes.openInTabText} variant="subtitle1">
            Open in a new tab
          </Typography>
          <Image src="/icons/website-link-icon.svg" width={20} height={20} />
        </ButtonBase>
      </div>
    </div>
  );
};
export default DrawerHeader;
