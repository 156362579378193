import router from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Theme, useMediaQuery } from '@material-ui/core';

import { loadIndustryExperience } from '@/client/redux/actions';
import { IOpenDetails } from './types';

const useDrawerDetailes = () => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [detailedId, setDetailedId] = useState<string | null>(null);
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const onOpenDetails = useCallback(
    ({ id, pageType, applicationID, isAdmin = false }: IOpenDetails) =>
      () => {
        if (isDesktop) {
          setDetailedId(id);
          setApplicationId(applicationID ?? null);
          setIsDrawerOpen(true);
          return;
        }

        if (isAdmin) {
          window.open(`/${pageType}/${id}`);
          return;
        }

        router.push(`/${pageType}/${id}`);
      },
    [isDesktop],
  );

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  useEffect(() => {
    dispatch(loadIndustryExperience());
  }, [dispatch]);

  return {
    applicationId,
    detailedId,
    isDrawerOpen,
    setIsDrawerOpen,
    onOpenDetails,
    closeDrawer,
  };
};

export default useDrawerDetailes;
