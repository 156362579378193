import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(10),
  },
  zeroText: {
    paddingTop: theme.spacing(10),
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.0016em',
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
