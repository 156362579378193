import Image from 'next/image';
import useStyles from './styles';
import { IZeroStateProps } from './types';

export const ZeroState = ({ text, button = null, ...rest }: IZeroStateProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container} {...rest}>
      <Image src="/images/zero-state.svg" width={238} height={180} alt="No results" />
      {text && <p className={classes.zeroText}>{text}</p>}
      {button}
    </div>
  );
};

export default ZeroState;
