import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0, -20),
    height: 60,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  backBtn: {
    padding: 0,
    width: 'auto',
    height: '100%',
    margin: 'auto 0',
    marginLeft: theme.spacing(25),
  },
  openInTab: {
    display: 'flex',
    marginRight: theme.spacing(20),
  },
  openInTabText: {
    margin: 'auto 0',
    marginRight: theme.spacing(5),
  },
}));

export default useStyles;
