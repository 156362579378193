import { makeStyles } from '@material-ui/core';

const useCardStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
    padding: theme.spacing(10),
    position: 'relative',
    boxShadow: '0px 4px 32px rgba(57, 93, 148, 0.08)',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(9),
      padding: theme.spacing(15),
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '4px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(12),
    },
  },
  avatarWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: 'fit-content',
    boxShadow: `0 0 0 1px ${theme.palette.white}, 0 0 0 2px ${theme.palette.border}`,
    marginRight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(12),
    },
  },
  avatar: {
    minWidth: theme.spacing(25),
    width: theme.spacing(25),
    height: theme.spacing(25),
    borderRadius: '50%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(38),
      width: theme.spacing(38),
      height: theme.spacing(38),
    },
  },
  divider: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: theme.palette.darkBlue,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.darkBlue,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.md,
      fontWeight: 500,
      lineHeight: theme.typography.fonts.lineHeight.md,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      lineHeight: '20px',
    },
  },
  secondarySubtitle: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.secondaryBlack,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  button: {
    width: '100%',
    height: 'auto',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    fontSize: theme.typography.fonts.size.md,
    lineHeight: theme.typography.fonts.lineHeight.md,
    '&:first-child': {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  secondaryButton: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.border}!important`,
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(11),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0, 5),
    borderRadius: theme.spacing(8),
    backgroundColor: theme.palette.bg,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  badgeTitle: {
    fontSize: theme.typography.fonts.size.sm,
    fontWeight: 500,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.lightBlue,
    whiteSpace: 'nowrap',
  },
  badgeIcon: {
    width: theme.spacing(6),
    minWidth: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(4),
    lineHeight: '0',
  },
  readMoreTextWrapper: {
    flexGrow: 1,
  },
  cardHover: {
    '&:hover': {
      backgroundColor: theme.palette.bgForFooter,
      cursor: 'pointer',
    },
  },
  secondaryPaymentText: {
    fontSize: theme.typography.fonts.size.sm,
    fontWeight: 400,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.secondaryBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.md,
      lineHeight: theme.typography.fonts.lineHeight.md,
    },
    [theme.breakpoints.up('md')]: {
      width: 'max-content',
      marginTop: theme.spacing(4),
      fontSize: '16px',
      lineHeight: '18px',
      whiteSpace: 'nowrap',
    },
  },
  tooltip: {
    '& .MuiButtonBase-root': {
      marginRight: theme.spacing(-4),
    },
  },
  actionDropdown: {
    cursor: 'pointer',
  },
}));

export default useCardStyles;
