import ReactHtmlParser from 'react-html-parser';
import clsx from 'clsx';
import { ITextHtmlProps } from './types';
import useStyles from './styles';
import useTextHtml from './useTextHtml';

export const TextHtml = ({ text, className, children }: ITextHtmlProps) => {
  const classes = useStyles();
  const { options } = useTextHtml(children);

  return (
    <div className={clsx(classes.root, className)}>{ReactHtmlParser(text ?? '', options)}</div>
  );
};

export default TextHtml;
