import React from 'react';
import clsx from 'clsx';
import { getSmallImageUri } from '@/client/utils';
import { ICustomImageProps } from './types';
import useStyles from './styles';
import useCustomImage from './useCustomImage';

export const CustomImage = ({
  alt,
  imageUri,
  defaultImg,
  user,
  userInitialsClass,
  className,
  ...props
}: ICustomImageProps) => {
  const classes = useStyles();
  const { hasError, onErrorLoad } = useCustomImage();

  return (
    <div className={clsx(classes.wrapper, className)} {...props}>
      {!hasError && imageUri ? (
        <img
          src={getSmallImageUri(imageUri)}
          alt={alt ?? 'image'}
          className={classes.image}
          onError={onErrorLoad}
        />
      ) : (
        <>
          {user ? (
            <div className={classes.defaultImageWrapper}>
              <img
                src={defaultImg ?? '/icons/avatar.svg'}
                alt={`${user?.firstName} ${user?.lastName}`}
                className={classes.image}
              />
              <div className={clsx(classes.userInitials, userInitialsClass)}>
                {user
                  ? `${user.firstName[0].toLocaleUpperCase()}${user.lastName[0].toLocaleUpperCase()}`
                  : ''}
              </div>
            </div>
          ) : (
            <img
              src={defaultImg ?? '/images/default-logo.svg'}
              alt="default logo"
              className={classes.image}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomImage;
