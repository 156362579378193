import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  defaultImageWrapper: {
    height: '100%',
    position: 'relative',
  },
  userInitials: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '20px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
