import { useCallback, useState } from 'react';

const useCustomImage = () => {
  const [hasError, setHasError] = useState(false);

  const onErrorLoad = useCallback(() => setHasError(true), []);

  return {
    hasError,
    onErrorLoad,
  };
};

export default useCustomImage;
