import { useMemo, isValidElement, ReactNode } from 'react';
import { convertNodeToElement } from 'react-html-parser';
import type { DomElement } from 'htmlparser2';

const useTextHtml = (children: ReactNode) => {
  const options = useMemo(
    () => ({
      transform: function transform(node: DomElement | ReactNode, index: number) {
        if (isValidElement(node)) {
          return node;
        }
        return convertNodeToElement(node, index, transform);
      },
      preprocessNodes: function preprocessNodes(nodes: DomElement[]) {
        if (!nodes.length || !children) {
          return nodes;
        }

        const lastNode = nodes[nodes.length - 1];

        if (lastNode.children && lastNode.name !== 'figure') {
          preprocessNodes(lastNode.children);
        } else {
          nodes.push(children);
        }

        return nodes;
      },
    }),
    [children],
  );

  return {
    options,
  };
};

export default useTextHtml;
