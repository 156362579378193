import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& ol, & ul': {
      padding: 'revert',
    },
  },
}));

export default useStyles;
