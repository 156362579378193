import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    boxShadow: 'none',
    width: '80%',
  },
}));

export default useStyles;
