import { Theme, useMediaQuery } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { IUseDetailedDrawerProps } from './types';

const useDetailedDrawer = ({
  showOnlyOnDesktop,
  isDrawerOpen,
  onDrawerStateChange,
}: IUseDetailedDrawerProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const showDrawerHandler = useCallback(
    (open?: boolean) => () => {
      return open === undefined
        ? onDrawerStateChange((isOpen) => !isOpen)
        : onDrawerStateChange(open);
    },
    [onDrawerStateChange],
  );

  useEffect(() => {
    if (isDrawerOpen && showOnlyOnDesktop && !isDesktop) {
      onDrawerStateChange(false);
    }
  }, [isDrawerOpen, isDesktop, showOnlyOnDesktop, onDrawerStateChange]);

  return {
    showDrawerHandler,
  };
};

export default useDetailedDrawer;
