import { userRoleSelector } from '@/client/redux/selectors';
import { UserRole } from '@/enums';
import { hasNumberValue } from '@/client/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useRoleGates = (compareRoles: UserRole[]) => {
  const role = useSelector(userRoleSelector);

  const hasAccess = useMemo(() => {
    if (!role || !hasNumberValue(role)) {
      return false;
    }

    return compareRoles.includes(role);
  }, [compareRoles, role]);

  return {
    hasAccess,
  };
};

export default useRoleGates;
